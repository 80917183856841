//Credits to https://codepen.io/cipherbeta/pen/YLdVjw

:root {
	--font-size: 7.8vw;
}

@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch {
    position: relative;
    color: white;
    font-size: var(--font-size);
}

.glitch:hover {
    position: relative;
    color: white;
    font-size: var(--font-size);
    animation: glitch-skew 1s infinite linear alternate-reverse;
    &::before {
        @include glitchCopy;
        left: 2px;
        text-shadow: -2px 0 #f50514;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim 4s infinite linear alternate-reverse;
    }

    &::after {
        @include glitchCopy;
        left: -2px;
        text-shadow: -2px 0 #00fff9, 2px 2px #f50514;
        animation: glitch-anim2 1s infinite linear alternate-reverse;
    }
}

@keyframes glitch-anim {
    $steps: 20;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(150) + px, 9999px, random(150) + px, 0);
            transform: skew((random(150) / 150) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(150) + px, 9999px, random(150) + px, 0);
            transform: skew((random(150) / 150) + deg);
        }
    }
}

@keyframes glitch-skew {
    $steps: 10;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(3) - 1) + deg);
        }
    }
}
